import React from 'react';
import ReactDOMClient from 'react-dom/client';
import 'index.css';
import axios from 'axios';
import { Base } from 'AuthorizationContext';
import * as Sentry from '@sentry/react';
import pjson from '../package.json';
import CookieConsent from 'react-cookie-consent';
import { Status } from 'components/Status';
import { ReactComponent as SteamLogo } from 'assets/images/steam.svg'; 
import { ReactComponent as DiscordLogo } from 'assets/images/discord.svg'; 

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/`;
axios.defaults.withCredentials = true;
axios.defaults.headers.accept = 'application/json';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: 'turing-quest-controller@v' + pjson.version,
});

function render() {
  document.body.classList.add('bg-secondary');
  const container = document.getElementById('root');
  const root = ReactDOMClient.createRoot(container);
  root.render(
    <div className="bg-gradient-to-b from-secondary-dark to-secondary text-white">
      <Status/>
      <Base/>
      <div className="text-center w-full underline py-4">
        <div className="mb-4">
          <a rel="noreferrer" className="inline-block" target="_blank" href="https://turingquest.com/steam">
            <SteamLogo className="w-6 inline mr-3"/>
            <span className="inline">Download on Steam</span>
          </a>
        </div>
        <div className="mb-4">
          <a rel="noreferrer" className="inline-block" target="_blank" href="https://discord.gg/ucq2wusPfD">
            <DiscordLogo className="w-6 inline mr-3"/>
            <span className="inline">Join Discord</span>
          </a>
        </div>
        <div>
          <a className="px-2" target="_blank" rel="noreferrer" href="https://krani.al">Kranial</a> 
          <a className="px-2" target="_blank" rel="noreferrer" href="/terms.txt">Terms</a>
          <a className="px-2" target="_blank" rel="noreferrer" href="/privacy.txt">Privacy</a>
          <a className="px-2" href="mailto:hello@krani.al">Help</a>
        </div>
      </div>
      <div className="text-center pb-4">
       <span className="inline-block text-sm text-white">
          v{ pjson.version }
        </span>
      </div>
      <CookieConsent
        disableStyles={true}
        containerClasses="bg-primary px-2 py-2 fixed w-full"
        contentClasses="text-black px-1 my-2 float-left text-xs"
        buttonClasses="btn btn-secondary float-right my-1 text-xs"
      >
        This website uses cookies.
      </CookieConsent>
    </div>
  );
}

render();
