import React from 'react';
import axios from 'axios';
import { error } from 'logger';

export class Status extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      issues: false,
      interval: null,
    };

    this.getStatus = this.getStatus.bind(this);
  }
  
  componentDidMount() {
    this.getStatus();
    this.setState({
      interval: setInterval(this.getStatus, 60000)
    });
  }
  
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  getStatus() {
    const url = `${process.env.REACT_APP_STATUS_PAGE}/api/v2/summary.json`;
    
    axios.get(url, { withCredentials: false })
      .then(r => {
        const data = r.data.components.filter(i => i.name === 'Turing Quest');
        
        if (data.length) {
          const game = data[0];
          let issues = false;
          
          if (game.status !== 'operational') {
            issues = true;
          }
          
          this.setState({ issues });
        }
      })
      .catch(e => error('Status request failed', e));
  }
  
  render() {
    return (
      <div>
        {this.state.issues &&
          <div className="mb-4">
            <div className="text-center sm:text-left bg-tertiary font-bold text-white px-4 py-1">
              <span className="inline-block py-2">
                We may be experiencing server issues right now.
              </span>
              <div className="sm:float-right text-center mt-2">
                <a
                  className="btn btn-white text-xs"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_STATUS_PAGE}
                >
                  Check Status
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
