import React from 'react';
import Home from 'components/Home.jsx';
import Register from 'components/Register.jsx';

const AuthorizationContext = React.createContext();

export class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setPlayerAndToken: this.setPlayerAndToken,
      token: localStorage.getItem('token'),
      player: localStorage.getItem('player'),
      mode: localStorage.getItem('mode'),
    };
  }
  
  setPlayerAndToken = (player, token, mode) => {
    this.setState({ player, token, mode });
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.token !== prevState.token) {
      if (this.state.token === null) {
        localStorage.removeItem('token')
      } else {
        localStorage.setItem('token', this.state.token)
      }
    }
    
    if (this.state.player !== prevState.player) {
      if (this.state.player === null) {
        localStorage.removeItem('player')
      } else {
        localStorage.setItem('player', this.state.player)
      }
    }
    
    if (this.state.mode !== prevState.mode) {
      if (this.state.mode === null) {
        localStorage.removeItem('mode')
      } else {
        localStorage.setItem('mode', this.state.mode)
      }
    }
  }

  render() {
    return (
      <AuthorizationContext.Provider value={this.state}>
        {!!this.state.token
          ? <Home context={this.state} />
          : <Register context={this.state} />
        }
      </AuthorizationContext.Provider>
    );
  }
}

export const AuthorizationConsumer = AuthorizationContext.Consumer;
