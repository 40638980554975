import React from 'react';
import { AuthorizationConsumer } from 'AuthorizationContext.jsx';
import { log } from 'logger';
import Connector, { Events, Stages } from '@turing-quest/connector';
import { ReactComponent as Logo } from 'assets/images/icon-horizontal.svg'; 

class Home extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      reconnect: null,
      connector: null,
      status: 'Pending',
      data: {},
      submission: '',
      assignedSubmission: '',
      submitDisabled: false,
    }
    
    this.handlePlayerState = this.handlePlayerState.bind(this);
    this.handleSpectatorState = this.handleSpectatorState.bind(this);
    this.handlePromptSubmit = this.handlePromptSubmit.bind(this);
    this.handleVoteSubmit = this.handleVoteSubmit.bind(this);
    this.handleSpectatorVoteSubmit = this.handleSpectatorVoteSubmit.bind(this);
    this.handleAnswerSubmit = this.handleAnswerSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.connectPlayer = this.connectPlayer.bind(this);
  }
  
  componentDidMount() {
    const connector = new Connector({
      debug: process.env.REACT_APP_DEBUG === 'true',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: process.env.REACT_APP_PUSHER_PORT,
      wssPort: process.env.REACT_APP_PUSHER_PORT,
      authEndpoint: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/broadcasting/auth`,
      forceTLS: process.env.REACT_APP_PUSHER_FORCE_TLS === 'true',
      token: this.props.context.token,
    });
    
    connector.on('initialized', () => this.setState({ status: 'Connected' }));
    connector.on('connecting', () => {
      this.setState({
        status: 'Connecting',
        data: {},
      });
    });
    connector.on('connected', () => {
      clearTimeout(this.state.reconnect);
      
      this.setState({
        status: 'Connected',
        reconnect: null,
      });
    });
    connector.on('unavailable', () => {
      this.setState({
        status: 'Connection Unavailable',
        data: {},
      });
    });
    connector.on('disconnected', () => {
      clearTimeout(this.state.reconnect);
      
      this.setState({
        status: 'Disconnected',
        data: {},
        reconnect: setTimeout(() => {
          if (this.state.status !== 'Connected') {
            window.location.reload();
          }
        }, 5000),
      }, () => this.connectPlayer());
    });
    connector.on('failed', () => {
      clearTimeout(this.state.reconnect);
      
      this.setState({
        status: 'Connection Failed',
        data: {},
        reconnect: setTimeout(() => {
          if (this.state.status !== 'Connected') {
            window.location.reload();
          }
        }, 5000),
      }, () => this.connectPlayer());
    });
    
    this.setState({ connector }, () => this.connectPlayer());
  }
  
  connectPlayer() {
    if (
      this.props.context.mode === null
      || (this.props.context.mode !== 'spectator' && this.props.context.mode !== 'player')
    ) {
      this.resetPlayer('Mode not set');
      return;
    }

    const connector = this.state.connector[this.props.context.mode](this.props.context.player)
      .error((e) => {
        if (e?.status === 401) {
          this.resetPlayer(e);
        }
        
        console.error('Websocket disconnected', e);
        this.state.connector.disconnect();
      });
      
    if (this.props.context.mode === 'player') {
      connector
        .listen(Events.Server.PlayerDeleting, (e) => this.resetPlayer(e))
        .listenForClient(Events.Game.GamePlayerState, (e) => this.handlePlayerState(e))
        .subscribed(() => this.send(new Events.Player.PlayerGetState()));
    }
    
    if (this.props.context.mode === 'spectator') {
      connector
        .listen(Events.Server.SpectatorDeleting, (e) => this.resetPlayer(e))
        .listenForClient(Events.Game.GameSpectatorState, (e) => this.handleSpectatorState(e))
        .subscribed(() => this.send(new Events.Spectator.SpectatorGetState()));
    }
  }

  componentWillUnmount() {
    if (this.state.connector !== null) {
      log('Disconnecting');
      this.state.connector.disconnect();
      this.setState({ connector: null });
    }
  }
  
  handleChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'submission') value = value.replace('?', '');

    this.setState({
      [name]: value,
    });
  }
  
  send(event) {
    this.setState({ submitDisabled: true }, () => {
      this.state.connector[this.props.context.mode](this.props.context.player).send(event);
      log('Message sent', event);
    });
  }
  
  handlePlayerState(message) {
    this.setState({
      data: message,
      submission: '',
      assignedSubmission: '',
      submitDisabled: false,
    });
    
    log('Game player state message received', message);
  }
  
  handleSpectatorState(message) {
    this.setState({
      data: message,
      submitDisabled: false,
    });
    
    log('Game spectator state message received', message);
  }
  
  resetPlayer(event) {
    log('Player reset', event);
    this.props.context.setPlayerAndToken(null, null, null);
  }
  
  handlePromptSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = new Events.Player.PlayerPromptSubmission({
      submission: formData.get('submission'),
      round: this.state.data.round,
    });
    
    this.send(response);
  }
  
  handleVoteSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = new Events.Player.PlayerVoteSubmission({
      vote: formData.get('vote'),
      round: this.state.data.round,
    });
    
    this.send(response);
  }
  
  handleSpectatorVoteSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = new Events.Spectator.SpectatorVoteSubmission({
      vote: formData.get('vote'),
      round: this.state.data.round,
    });
    
    this.send(response);
  }
  
  handleAnswerSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = new Events.Player.PlayerAssignedSubmission({
      assignedSubmission: formData.get('assignedSubmission'),
      round: this.state.data.round,
    });
    
    this.send(response);
  }
  
  render() {
    return (
      <div className="text-center max-w-lg mx-auto px-4 py-10">
        <div>
          <Logo className="mb-8 w-72 mx-auto"/>
        </div>
        { process.env.REACT_APP_DEBUG === 'true' &&
          <div className="mb-4">
            <p>Status: { this.state.status }</p>
            <p>Stage: {this.state.data?.stage}</p>
          </div>
        }
        <div className="text-center p-3">
          <span className="text-base">
            <span className={`pr-3 text-${this.state.status === 'Connected' ? 'primary' : 'tertiary' }`}>
              ●
            </span>
            <span className={`text-${this.state.status === 'Connected' ? 'primary' : 'white' }`}>
              { this.state.status }
            </span>
          </span>
        </div>
        { ['Connection Failed', 'Disconnected', 'Connection Unavailable'].includes(this.state.status) &&
          <div className="text-center my-3">
            <span className="block text-base bg-tertiary p-3">Reload the page to reconnect.</span>
            <button
              className="btn btn-primary mt-3"
              onClick={() => window.location.reload() }
            >
              Reconnect
            </button>
          </div>
        }
        { this.props.context.mode === 'player' &&
          <div>
            { (this.state.data?.stage === Stages.TRANSITIONING ||
              this.state.data?.stage === Stages.ANSWER_GENERATED ||
              this.state.data?.stage === Stages.WELCOME ||
              this.state.data?.stage === Stages.INTRODUCTION ||
              this.state.data?.stage === Stages.ADVISORY ||
              this.state.data?.stage === Stages.SCORE ||
              this.state.data?.stage === Stages.OVERVIEW ||
              this.state.data?.stage === Stages.COMPLETE ||
              this.state.data?.stage === Stages.TALLY) &&
              <p className="mb-4 font-semibold">Waiting for Game</p>
            }
            { this.state.data?.stage === Stages.LOBBY &&
              <p className="mb-4 font-semibold">Waiting for the host to start the game.</p>
            }
            { this.state.data?.stage === Stages.STARTING &&
              <p className="mb-4 font-semibold">Game is Starting</p>
            }
            { (this.state.data?.stage === Stages.PROMPT_SUBMITTED ||
              this.state.data?.stage === Stages.ANSWER_SUBMITTED) &&
              <p className="mb-4 font-semibold">Waiting for Other Players</p>
            }
            { this.state.data?.stage === Stages.PROMPT &&
              <form onSubmit={this.handlePromptSubmit}>
                <p className="mb-4 font-semibold">Create a funny question by filling in the blank.</p>
                <p className="mb-2">{this.state.data.questions[this.state.data.round].replace('%s', '＿＿＿')}</p>
                <div className="mb-4">
                  <input
                    required
                    type="text"
                    name="submission"
                    placeholder="answer"
                    maxLength={20}
                    onChange={this.handleChange}
                    value={this.state.submission}
                    className="max-w-xs text-black mt-2 px-4 py-3 rounded form-input w-full text-center focus-tertiary"
                    autoCapitalize="none"
                    autoComplete="off"
                  />
                  { this.state.submission.length > 0 &&
                    <span className="block mt-2 text-xs">
                      Characters Remaining: { 20 - this.state.submission.length }
                    </span>
                  }
                </div>
                <p className="mb-4 text-sm">
                  <span className="rounded bg-tertiary px-2 py-1 text-xs mr-2 font-semibold">Tip:</span>
                  Avoid niche topics and inside jokes that our AI won't understand.
                </p>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.submitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            }
            { this.state.data?.stage === Stages.ANSWER &&
              <form onSubmit={this.handleAnswerSubmit}>
                <p className="mb-4 font-semibold">Write a funny answer to the question below.</p>
                <p className="mb-2">{this.state.data.assignedQuestions[this.state.data.round]}</p>
                <div className="mb-4">
                  <input
                    required
                    type="text"
                    autoComplete="off"
                    name="assignedSubmission"
                    placeholder="answer"
                    maxLength={100}
                    onChange={this.handleChange}
                    value={this.state.assignedSubmission}
                    className="max-w-xs text-black mt-2 px-4 py-3 rounded form-input w-full text-center focus-tertiary"
                  />
                  { this.state.assignedSubmission.length > 0 &&
                    <span className="block mt-2 text-xs">
                      Characters Remaining: { 100 - this.state.assignedSubmission.length }
                    </span>
                  }
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.submitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            }
            { ((this.state.data?.stage === Stages.PROMPT_JUDGE || this.state.data?.stage === Stages.PROMPT_JUDGE_SUBMITTED) &&
              this.state.data?.assignedQuestions?.[this.state.data?.round] === this.state.data?.promptJudge?.[this.state.data?.round]) &&
              <div>
                <p className="mb-4 font-semibold">Waiting for everyone's votes.</p>
                <p className="mb-4 font-base">Players are judging your response!</p>
              </div>
            }
            { (this.state.data?.stage === Stages.PROMPT_JUDGE_SUBMITTED &&
              this.state.data?.assignedQuestions?.[this.state.data?.round] !== this.state.data?.promptJudge?.[this.state.data?.round]) &&
              <div>
                <p className="mb-4 font-semibold">Waiting for everyone's votes.</p>
                <p className="mb-4 font-base">Other players are still voting!</p>
              </div>
            }
            { (this.state.data?.stage === Stages.PROMPT_JUDGE &&
              this.state.data?.assignedQuestions?.[this.state.data?.round] !== this.state.data?.promptJudge?.[this.state.data?.round]) &&
              <form onSubmit={this.handleVoteSubmit}>
                <p className="mb-4 font-semibold">Choose your favorite answer. Only one is not AI generated!</p>
                <p className="mb-4">{this.state.data.promptJudge[this.state.data.round]}</p>
                <div className="mb-4">
                  <fieldset className="text-left text-base rounded bg-white text-black px-4 py-1 m-2 divide-y divide-light divide-y-2">
                    {this.state.data.answerJudge[this.state.data.round].map((answer, i) => {
                      return (
                        <label key={i} className="block py-2 first:pt-2 last:pb-2" htmlFor={`answer-${i}`}>
                          <input id={`answer-${i}`} type="radio" name="vote" className="input-radio mr-2" value={i}/>
                          <span><strong>{i + 1}.</strong> {answer}</span>
                        </label>
                      );
                    })}
                  </fieldset>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.submitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            }
          </div>
        }
        { this.props.context.mode === 'spectator' &&
          <div>
            { this.state.data?.stage !== null && ![Stages.PROMPT_JUDGE_SUBMITTED, Stages.PROMPT_JUDGE].includes(this.state.data.stage) &&
              <div>
                <p className="mb-4 font-semibold">Waiting for voting round.</p>
                <p className="mb-4 font-base">Stay tuned, soon you'll be able to cast votes for your favorite responses.</p>
              </div>
            }
            { this.state.data?.stage === Stages.PROMPT_JUDGE_SUBMITTED &&
              <div>
                <p className="mb-4 font-semibold">Waiting for everyone's votes.</p>
                <p className="mb-4 font-base">Other players are still voting!</p>
              </div>
            }
            { this.state.data?.stage === Stages.PROMPT_JUDGE &&
              <form onSubmit={this.handleSpectatorVoteSubmit}>
                <p className="mb-4 font-semibold">Choose your favorite answer. Only one is not AI generated!</p>
                <p className="mb-4">{this.state.data.promptJudge[this.state.data.round]}</p>
                <div className="mb-4">
                  <fieldset className="text-left text-base rounded bg-white text-black px-4 py-1 m-2 divide-y divide-light divide-y-2">
                    {this.state.data.answerJudge[this.state.data.round].map((answer, i) => {
                      return (
                        <label key={i} className="block py-2 first:pt-2 last:pb-2" htmlFor={`answer-${i}`}>
                          <input id={`answer-${i}`} type="radio" name="vote" className="input-radio mr-2" value={i}/>
                          <span><strong>{i + 1}.</strong> {answer}</span>
                        </label>
                      );
                    })}
                  </fieldset>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.submitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            }
          </div>
        }
      </div>
    );
  }
}

export default function home() {
  return (
    <AuthorizationConsumer>
      {state => <Home context={state} />}
    </AuthorizationConsumer>
  );
};
